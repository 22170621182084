button.neumorphic {
  container-type: inline-size;
  aspect-ratio: 1/1;

  border: 2px solid #5a5a5a;  
  border-radius: 40px; /* Arredondamento das bordas */
 
  background: none; /* Cor de fundo */
  display: grid;
  height: 30px;
  width: 150px;
  place-content: center;
  gap: 1rem;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3); /* Sombra */
  outline: none;  
  transition: all 0.1s;
  
  &:hover, &:focus-visible {
    transform: scale(1.1); /* Efeito de escala ao passar o mouse */
  }
  &:active, &.active{
    box-shadow:
      10px 10px 20px rgba(0, 0, 0, 0.3), /* Sombra */
      inset 5px 5px 10px rgba(0, 0, 0, 0.5), /* Sombra interna */
      inset -5px -5px 10px rgba(255, 255, 255, 0.75); /* Sombra interna */
    color: hsl(10 80% 50%); /* Cor do texto */
  }
  > span {
    color: #202020; /* Cor do texto */
    font-size: 15px; /* Tamanho do texto */
    font-family: system-ui, sans-serif; /* Fonte do texto */
  }
}

.buttons {
  display: grid;
  width: min(75rem, 100%);
  margin-inline: auto;
  grid-template-columns: repeat(auto-fit, minmax(min(25%, 100%), 1fr)); /* Colunas responsivas */
}

/*Tela Celular*/
@media screen and (max-width: 768px) {
  button.neumorphic {

    border-radius: 0px; /* Arredondamento das bordas */
    width: 85px;

    > span {

      font-size: 13px;
    }

  }
}