
.App-backGround {
  background-color: #F2EFE5;
}

.App-backGround.dark-theme {
        background-color: #2b2b2b;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items:center;
  font-size: calc(10px + 2vmin);
}



