:root {
    --color-1: #204b5e;
    --color-2: #204b5e;
    --color-3: #000000;
    --color-4: #1F2937;
    --active: #204b5e;
    --letter-size: 36rem;

  }

  
  * {
    box-sizing: border-box;
  }
  
  .carousel {
    position: relative;
    width: var(--letter-size);
    height: var(--letter-size);
    perspective: 800px;
    transform-style: preserve-3d;

  }

  
  .letter-container {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: 
      rotateY(calc(var(--offset) * 50deg)) 
      scaleY(calc(1 + var(--abs-offset) * -0.4))
      translateZ(calc(var(--abs-offset) * -30rem))
      translateX(calc(var(--direction) * -5rem));
    filter: blur(calc(var(--abs-offset) * 1rem));
    transition: all 0.4s ease-out;
  }
  
  .Group-componente
  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 1200px;
    width: 100%;
    gap: 30px;

  }


  .letter {
    width: 100%;
    height: 100%;
    padding: 2rem;
    background-color: hsl(198deg, 20%, calc(100% - var(--abs-offset) * 109%), 1);
    border-radius: 1rem;
    color: var(--color-3);
    text-align: justify;
    transition: all 0.4s ease-out;
    
    h2 {
      text-align: center;
      font-size: 2rem;
      font-weight: bold;
      margin: 0 0 0.7em;
      color: var( --color-4);
    }
    
    p, h2 {
      transition: all 0.3s ease-out;
      opacity: var(--active);
    }
  }
  
  .nav {
    color: #fdfac7;
    font-size: 5rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    z-index: 2;
    cursor: pointer;
    user-select: none;
    background: unset;
    border: unset;
    
    &.left {
      transform: translateX(-100%) translateY(-50%);
    }
    
    &.right {
      right: 0;
      transform: translateX(100%) translateY(-50%);
    }
  } 

  .Group-projectsNavigator{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .Group-projectsButtons{
    align-items: center;
    justify-content: top;
    align-self: center;
    background-color: #C7C8CC; 
    border: 2px solid #B4B4B8; 
    border-radius: 6px;
    height: 100%;
    width: 200px;
    text-shadow: #0000002c 2px 2px;
    display: flex;
    flex-direction: column;
    >h2{
      color: #292929;
    }
  }
  .Group-projectsInner
  {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 10px;
    

    
    >button {
      padding: 10px 20px; /* Adicionando padding uniforme */
      justify-content: center;
      box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.3);
      font-size: 16px;
      border-radius: 4px;
      width: 100%;
      height: 100%; /* Altura fixa */
      background-color: #B4B4B8; /* Cor de fundo */
      border: none; /* Removendo a borda */
      cursor: pointer; /* Cursor do mouse */
      transition: background-color 0.3s; /* Transição suave */
  
    }
    
    >button:hover {
      position: relative;
      bottom: -1%;
      background-color: #9d9d9d; /* Mudando a cor de fundo ao passar o mouse */
      box-shadow: 4px 4px 0px rgba(0, 0, 0, 0.3);
    }
  }
  .Text-projetos{
    font-size: 55px;
    text-shadow: #0000002c 6px 7px;
    color: rgb(0, 0, 0);
  }

.Text-projetos.dark-theme{
    color: #ffffff;
}

  @media screen and (max-width: 768px) {


    
    .Group-projectsInner
    {
      >button {
        font-size: 18px;
      }    
    }

    .letter {
      width: 80%;
      height: 100%;
      position: relative;
      left: 10%;   
    }
    .Group-componente
    {
      height: auto;

  
    }  
    
    .Group-projectsButtons{

      width: 100%;
      height: auto;
    }

    
    .nav {
      color: #000000;
      &.left {
        transform: translateX(1%) translateY(-50%);
      }
      
      &.right {
        right: 0;
        transform: translateX(-1%) translateY(-50%);
      }
    } 

    .carousel {
      height: 800px;
      perspective: 30px;
      width: 412px;
    }
    .Group-projectsNavigator{
      height: auto;
      flex-direction: column;

    }
    .Group-projectsButtons{

      width: 100%;
      height: auto;
      padding-bottom: 5%;
    }
}