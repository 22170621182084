.Group-btContact
{
    background-color: rgba(255, 255, 255, 0.448);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    gap: 10px;

}

.imgContact
{
    width: 60%;
    height: 60%;
    filter: grayscale(100%) invert(100%);
}

.bt-Contact
{

    width: 20%;
    height: 70%;
}
.bt-Text
{
    font-size: 21px;
    width: 60%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .txt-row
    {
        gap: 6px;
        display: flex;
        flex-direction: row;    
    }
}

button.neumorphic-Contact {
  container-type: inline-size;
  aspect-ratio: 1/1;
  border: 5px solid #ffffff; /* Cor do contorno */
  border-radius: 5px; /* Arredondamento das bordas */
  
  background: none; /* Cor de fundo */
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3); /* Sombra */
  outline: none;  
  transition: all 0.1s;
  
  &:hover, &:focus-visible {
    transform: scale(1.1); /* Efeito de escala ao passar o mouse */
  }
  &:active, &.active{
    box-shadow:
      10px 10px 20px rgba(0, 0, 0, 0.3), /* Sombra */
      inset 5px 5px 10px rgba(0, 0, 0, 0.5), /* Sombra interna */
      inset -5px -5px 10px rgba(255, 255, 255, 0.75); /* Sombra interna */
    color: hsl(10 80% 50%); /* Cor do texto */
  }
  > span {
    color: #ffffff;
    font-size: 15px; /* Tamanho do texto */
    font-family: system-ui, sans-serif; /* Fonte do texto */
  }
}

.buttons {
  display: grid;
  width: min(75rem, 100%);
  margin-inline: auto;
  grid-template-columns: repeat(auto-fit, minmax(min(25%, 100%), 1fr)); /* Colunas responsivas */
}

/*Tela Celular*/
@media screen and (max-width: 768px) {
  button.neumorphic {
    border: 2px solid #9a8e59; /* Cor do contorno */
    border-radius: 0px; /* Arredondamento das bordas */
    width: 85px;

    > span {

      font-size: 13px;
    }

  }
  .bt-Text
    {
        font-size: 13px;
    }
.Group-btContact
{
    background-color: rgba(255, 255, 255, 0.448);
    width: 100%;
    height: 65px;
    gap: 5px;

}

}