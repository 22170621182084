.tumbler-wrapper {
    margin-left: auto;
    width: 60px;
    height: 30px;
    background-color: #07556b;
    border: 2px solid #5a5a5a; /* Adicionei a largura e estilo da borda */
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 6px;
    cursor: pointer;
    position: relative;
}
    .tumbler-wrapper.dark-theme {
        background-color: black;
    }
    

    .tumbler-wrapper.dark-theme .tumbler {
        /* Define a posi��o final quando o tema escuro est� ativo */
        transform: translateX(25px); /* Mova para a direita */
       
    }

.tumbler-wrapper svg {
    width: 15px;
    height: 15px;
}

.tumbler {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #fff;
    transition: transform 0.5s, background-color 0.5s;
    will-change: transform;
    /* Define a posi��o inicial */
    transform: translateX(-2px); /* Posi��o inicial */
}

.credits {
    display: none;
}

.sun-svg,
.moon-svg {
    display: none;
}

.main {
    width: 80vw;
    max-width: 500px;
    padding: 0 1rem;
    margin: auto;
}

.post {
    border-bottom: 1px dashed #222;
    margin-bottom: 1.5rem;
    transition: border-bottom 0.5s;
}

.post__title {
    font-weight: 700;
    margin: 0; /* Uniformizei as margens */
}

.post__title:first-child {
    margin-top: 0; /* Mantive isso para o primeiro t�tulo */
}

.footer {
    width: 80vw;
    max-width: 500px;
    padding: 1rem;
    margin: 0 auto 1.5rem;
}

.description {
    display: flex;
}

.description__text {
    margin-right: 0.25rem;
}

.loveSvg svg {
    display: block;
    height: 1rem;
    max-width: 1rem;
}

/* Adicionando uma media query para responsividade */
@media (max-width: 600px) {
    .tumbler-wrapper {
        width: 40px; /* Diminuindo a largura em telas menores */
        height: 25px; /* Diminuindo a altura em telas menores */
    }
    
    .tumbler {
        height: 18px; /* Ajustando o tamanho do tumbler */
        width: 18px;
    }

    .main, .footer {
        width: 90vw; /* Ampliando a largura em telas menores */
    }
}
