@import url('https://fonts.googleapis.com/css?family=Roboto:700');

#containerComp {
  color:#000000;
  font-size:31px;
  font-weight:bold;
  width:100%;
  bottom:45%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;  
  gap: 8px;
}

#flip {
  height:50px;
  overflow:hidden;
}

#flip > div > div {
  color:#fff;
  padding:4px 12px;
  height:45px;
  margin-bottom:45px;
  display:inline-block;
}

#flip div:first-child {
  animation: show 10s linear infinite;
}

#flip div div {
  background:#000;
}


#flip div:first-child div {
  background:#4ec7f3;
}
    #flip div:last-child div {
        background: linear-gradient(135deg, #d9db19, #2a1ce1); /* Gradiente */
    }


@keyframes show {
  0% {margin-top:0px;}
  5% {margin-top:0px;}
  20% { margin-top: -80px; }
  30% {margin-top:-80px;}
  45% {margin-top:-165px;}
  55% {margin-top:-165px;}
  70% {margin-top:-255px;}
  80% {margin-top:-255px;}
  85% {margin-top:-290px;}
  85.01% { margin-top: 30px;  }
  95% { margin-top: 0px; }
   
}
