.ImgLogo1
{
  width: 45%;
}

.Pin-aboutme
{
  position: relative;


  width: 100px;

}





.Pin-easycontact
{
  position: relative;
  bottom: 25px; 
  right: 10px;
  width: 70px;
  margin-right: -30px;
  margin-bottom: -40px;
}

.Group-easycontact
{
  position: relative;
  padding-top: 2%;
  height: 35%;
  width: 55%;

  padding-bottom: 2%;

  justify-content: center;
  display:flex;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
  flex-direction: column;
  gap: 5px;
  background-color: #C7C8CC; 
  border: 2px solid #5a5a5a; 
  align-items: center;
  border-radius: 10px;
}

.Group-Title
{
  justify-content: center;
  display: grid;
  margin-bottom: 5%;
}
.Group-aboutme{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  width: 80%;
  flex-direction: column;
}
.Group-aboutme_inner{
  width: 60%; 
  height: 555px; 

  background-color: #C7C8CC; 
  border: 2px solid #5a5a5a; 
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
  display: flex;
  gap: 18px;
  flex-direction: column;
  height: 600px;
  align-items: center;
  justify-content: center;
  padding-left: 1%;
  padding-right: 1%;
  border-radius: 10px;
}

.Title-aboutme{
  font-size: 45px; 
  font-weight: bold;
  text-shadow: #00000031 3px 5px;
}
.Subtitle-aboutme{
  font-size: 13px; 
  font-weight: bold;
  position: relative;
  left:90%;
  top: 2px;
  width: 50px;
  bottom: 10px;
  text-shadow: #00000031 1px 1px;
}

.Text-aboutme1{
  font-size: 18px; 
  height: 16%;    
}

.Text-aboutme2{
  font-size: 18px; 
  margin-top: 40px;
}

.Group-principal {
  width: 100%; 
  height: 900px; 
  padding-top: 1%;
  padding-left: 1%;
  padding-left: 1%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}


.Group-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.image-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 200px; /* Defina o tamanho da imagem conforme necessário */
}

.text-container {
  margin-left: 20px; /* Ajuste conforme necessário */
}

.text-container p {
  font-size: 18px; /* Defina o tamanho da fonte conforme necessário */
  font-weight: bold;
}

.custom-btn {
  width: 130px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
}

.btn-3 {
  background: rgb(0,172,238);
  background: linear-gradient(0deg, #204b5e 0%, #204b5e 100%);
  height: 40px;
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-3 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-3:before,
.btn-3:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
   background: #204b5e;
  transition: all 0.3s ease;
}
.btn-3:before {
  height: 0%;
  width: 2px;
}
.btn-3:after {
  width: 0%;
  height: 2px;
}
.btn-3:hover{
   background: transparent;
  box-shadow: none;
}
.btn-3:hover:before {
  height: 100%;
}
.btn-3:hover:after {
  width: 100%;
}
.btn-3 span:hover{
   color: #204b5e;
}
.btn-3 span:before,
.btn-3 span:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
   background: #204b5e;
  transition: all 0.3s ease;
}
.btn-3 span:before {
  width: 2px;
  height: 0%;
}
.btn-3 span:after {
  width: 0%;
  height: 2px;
}
.btn-3 span:hover:before {
  height: 100%;
}
.btn-3 span:hover:after {
  width: 100%;
}
/*Tela Celular*/
@media screen and (max-width: 768px) {
  .ImgLogo1
  {
    padding-top: 22%;
  }

  .Group-principal{
    flex-direction: column;
    width: 100%;
    gap:2px
  }
  .btn-3 {
  height: 70%;
  width: 50%;
  }

  .Group-easycontact
{
  position: relative;
  padding-top: 2%;
  height: 18%;
  width: 45%;


}
.Group-aboutme {
  width: 95%;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
.Group-aboutme_inner {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
}