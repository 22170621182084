


  .card-groups,
  .card-group,
  .card {
    aspect-ratio: 5 / 7;
  }
  
  .card-groups,
  .card-group,
  .little-card {
    aspect-ratio: 1 / 1;
  }

  
  .card-groups,
  .card-group,
  .big-card {  
    aspect-ratio: 5 / 7;
    width: 13vmin;
  }
  
  .card-group {
    position: absolute;
    transition: transform 400ms ease;
  }
  
  .card-group[data-status="unknown"] {
    transform: scale(0);
    transition: none;
  }
  
  .card-group[data-status="active"] {
    transition-delay: 300ms;
  }
  
  .card-group[data-status="after"] {
    transform: translateX(50%) scale(0);
  }
  
  .card-group[data-status="before"] {
    transform: translateX(-50%) scale(0);
  }
  
  .card-group[data-status="becoming-active-from-after"] {
    transform: translateX(50%) scale(0);
    transition: none;
  }
  
  .card-group[data-status="becoming-active-from-before"] {
    transform: translateX(-50%) scale(0);
    transition: none;
  }
  
  .card {
    background-color: rgba(255, 255, 255, 0.05);
    position: absolute;
    transition: transform 800ms cubic-bezier(.05,.43,.25,.95);
    
    background-position: center;
    background-size: cover;
  }
  
  .big-card {
    border-radius: 1vmin;
  }
  
  .little-card {
    width: 8vmin;
    border-radius: 2vmin;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: -1vmin 1vmin 2vmin rgba(0, 0, 0, 0.25);
    pointer-events: none;
  }
  
  .big-card:nth-child(6) {
    background-image: url("../../../public/imgs/personal1.png");
    transform: translateX(-10%) rotate(-1deg);
  }
  
  .big-card:nth-child(8) {
    background-image: url("../../../public/imgs/meNow.png") ;
     transform: rotate(2deg);
  }
  
  .big-card:nth-child(4) {
    background-image: url("../../../public/imgs/nasaSpaceApps.jpg") ;
    transform: translateX(-6%) rotate(-3deg);
  }
  
  .big-card:nth-child(2) {
    background-image: url("../../../public/imgs/2024Att.png") ;
    transform: translate(10%, 3%) rotate(5deg);
  }
  

  .little-card:nth-child(1) {
    background-image: url("../../../public/imgs/javascriptLogo.png");
  }
  
  .little-card:nth-child(3) {
    background-image: url("../../../public/imgs/dockerLogo.png");
  }
  
  .little-card:nth-child(5) {
    background-image: url("../../../public/imgs/javaLogo.jpg");
  }
  
  .little-card:nth-child(7) {
    background-image: url("../../../public/imgs/postgreLogo.png");
  }
  
  .card-group:hover > .card {  
    box-shadow: -2vmin 2vmin 3vmin rgba(0, 0, 0, 0.4);
  }
  
  .card-group:hover > .big-card:nth-child(2) {
    transform: translate(-75%, 16%) rotate(-24deg);
  }
  
  .card-group:hover > .big-card:nth-child(4) {
    transform: translate(-25%, 8%) rotate(-8deg);
  }
  
  .card-group:hover > .big-card:nth-child(6) {
    transform: translate(25%, 8%) rotate(8deg);
  }
  
  .card-group:hover > .big-card:nth-child(8) {
    transform: translate(75%, 16%) rotate(24deg);
  }
  
  .card-group:hover > .little-card:nth-child(1) {
    transform: translate(200%, -200%) rotate(-15deg);
  }
  
  .card-group:hover > .little-card:nth-child(3) {
    transform: translate(160%, -370%) rotate(15deg);
  }
  
  .card-group:hover > .little-card:nth-child(5) {
    transform: translate(-200%, -400%) rotate(15deg);
  }
  
  .card-group:hover > .little-card:nth-child(7) {
    transform: translate(-380%, -240%) rotate(-15deg);
  }

  @media screen and (max-width: 768px) 
  {
    .little-card {
      width: 14vmin;

    }
    .card-groups,
    .card-group,
    .big-card {  
      width: 35vmin;
    }
  }