.footer {
	background-color: #B4B4B8;
	color: white;
	padding: 20px 0;
	width: 100%;
	text-align: center;
	margin-bottom: -20px;

  }
  
  .footer-content {
	position: relative;
  }
  
  .footer-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	margin-top: -20px;
	height: 295%;
	object-fit: cover;
	opacity: 0.3; 
  }
  
  .footer-nail {
	padding-top: 20px;
  }

  .label-nail{
	position: absolute;
	bottom: 7%; 
	left: 50%; 
	transform: translateX(-50%); 
	font-size: 16px; 
	color: #404040;
	text-shadow: #5959592c 1px 3px;
	white-space: nowrap; 
  } 
  .label-nail2{
	position: absolute;
	top: 100%;
	left: 50%; 
	transform: translateX(-50%); 
	font-size: 11px; 
	color: #000000;

	white-space: nowrap; 
  } 

  @media screen and (max-width: 768px) 
  {
	.label-nail{

		font-size: 12px; 
		
	  } 
  }