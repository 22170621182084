.Group-header{
    width: 800px;
    
    height: 40px; 
    background-color: #C7C8CC; 
    border: 2px solid #5a5a5a; 
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
    border-radius: 25px;
    align-items: center;
    display: flex;
   
    padding-left: 1%;
    padding-right:1%;
    margin-top: 10px;
    position:fixed;
    align-items: center;
    justify-content: center;
    transition: opacity 0.5s ease; /* Transição suave de opacidade */
    z-index: 1000;  
  }
.display-flex{
  display: flex;
  flex-direction: row;
  gap: 10px;

}

  .Group-header.visible {
    opacity: 1; 
  }
  
  .Group-header.hidden {
    opacity: 0; 
  }

/*Tela Celular*/
@media screen and (max-width: 768px) {
  .Group-header{
    width: 100%;
    border-radius: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .display-flex{
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
}